import React, { useCallback, useEffect, useState } from "react";
import { withTheme } from "@material-ui/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import PageContainer, { CardPageItem } from "../custom/Page";
import HeaderPageItem from "../custom/HeaderPageItem";
import { CardContent, CircularProgress, Divider, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import { getContracts, getContractHistory } from "../actions/Contract";
import { getAgentPayments } from "../actions/Payment";
import Number from "../custom/Number";
import PropTypes from "prop-types";
import { Roles } from "../util";
import { ChevronRight } from "@material-ui/icons";
import { getUser2 } from "../actions";
import { getInfoStatement, getStatement } from "../actions/User";
import Moment from "react-moment";

const EarningsPage = ({ currentUser, theme, match }) => {
  const year = new Date().getFullYear() - 1;
  const [previousRoute, setPreviousRoute] = useState("/contracts");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contractType, setContractType] = useState(null);
  const [comissionGain, setComissionGain] = useState(null);
  const [comissionGainByMonth, setComissionGainByMonth] = useState([]);
  const [user, setUser] = useState({});
  const [valores, setValores] = useState({
    contracts: [],
    capitalInvested: 0,
    capitalGain: 0,
    earningsByMonth: [],
  });
  
  const tableStyles = {
    table: {
      width: "100%",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      fontSize: 14,
      textAlign: "center",
      overflow: "auto",
      whiteSpace: "nowrap",
    },
    thead: {
      fontSize: 12,
      color: "#FFBE2D",
      background: "#2a2a2a",
      borderBottom: "1px solid #535353",
      height: "35px",
    },
    th: {
      padding: "10px",
      textAlign: "center",
      verticalAlign: "middle",
      whiteSpace: "nowrap",
    },
    td:{
      minWidth: 140,
      padding: "0 12px",
      whiteSpace: "nowrap",
    },
    rowStyle: (index) => {
      return {
        backgroundColor: index % 2 ? "#141414" : "#0a0a0a",
        borderBottom: "1px solid #525252",
      };
    },
  };

  const getEarnings = useCallback(
    async (type = null) => {
      const contracts = await getContracts({ userId: user.id });

      const contractsFilteredByType = contracts.filter((c) => c.type.type === type);
      const contractsFilteredByYear = contractsFilteredByType.filter((c) => isWithinYear(c.initialDate, c.dueDate, year, type));
      
      let totalYield = 0;
      let totalCustody = 0;
      let totalByMonth = new Array(12).fill(0);
      const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

      const initialDate = new Date(year, 0, 1).toISOString().split("T")[0];
      const finalDate = new Date(year, 11, 31).toISOString().split("T")[0];

      let infoStatement = [];
      if ([Roles.Admin, Roles.FinanceManager].includes(currentUser.role)) {
        infoStatement = await getInfoStatement(user.id, initialDate, finalDate);
      } else {
        infoStatement = await getStatement(initialDate, finalDate);
      }

      contractsFilteredByYear.forEach((contract) => {
        if (!contract) return;
        totalCustody += contract.amount;
        const statementsFilteresByContract = infoStatement.filter(
          (i) => i.originId === contract.id && i.origin === "PROFIT"
        );

        statementsFilteresByContract.forEach((s) => {
          const month = s.statementDate.split("-")[1] - 1;
          totalByMonth[month] += s.value;
        });
        const sum = statementsFilteresByContract.reduce((acc, s) => acc + s.value, 0);
        contract.earning = sum;
        totalYield += sum;
      });

      const earningsByMonth = months.map((m, index) => ({
        key: `app.month.${m}`,
        value: totalByMonth[index],
      }));

      setValores((prevState) => {
        return {
          ...prevState,
          capitalGain: totalYield,
          earningsByMonth: earningsByMonth,
          capitalInvested: totalCustody,
          contracts: contractsFilteredByYear,
        };
      });
    },
    [currentUser, user, year]
  );

  const calculateComission = async () => {
    try {
      setLoading(true);
      const initialDate = new Date(year, 0, 1).toISOString().split("T")[0];
      const finalDate = new Date(year, 11, 31).toISOString().split("T")[0];
      let totalByMonth = new Array(12).fill(0);

      let infoStatement = [];
      if ([Roles.Admin, Roles.FinanceManager].includes(currentUser.role)) {
        infoStatement = await getInfoStatement(user.id, initialDate, finalDate);
      } else {
        infoStatement = await getStatement(initialDate, finalDate);
      }

      infoStatement = infoStatement.filter((i) => ["COMMISSION"].includes(i.origin));
      infoStatement.forEach((s) => {
        const month = s.statementDate.split("-")[1] - 1;
        totalByMonth[month] += s.value;
      });

      const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
      const earningsByMonth = months.map((m, index) => ({
        key: `app.month.${m}`,
        value: totalByMonth[index],
      }));

      const val = totalByMonth.reduce((accumulator, element) => accumulator + element, 0);
      setComissionGain(val);
      setComissionGainByMonth(earningsByMonth);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const reset = () => {
    setContractType(null);
    setComissionGain(null);
    setValores({
      contracts: [],
      capitalInvested: 0,
      capitalGain: 0,
      earningsByMonth: [],
    });
  };

  const isWithinYear = (startDate, endDate, year, type) => {
    if (!startDate || !endDate) return false;
    const start = new Date(startDate.replace(/-/g, "/"));
    const end = new Date(endDate.replace(/-/g, "/"));
    return type === "OPEN"
      ? start.getFullYear() <= year && end.getFullYear() >= year
      : type === "CLOSED"
        ? start.getFullYear() <= year && end.getFullYear() === year
        : false;
  };

  useEffect(() => {
    const { id } = match?.params;
    const loadUser = async () => {
      setLoading(true);
      if (id) {
        if ([Roles.Admin, Roles.FinanceManager].includes(currentUser?.role)) {
          try {
            const userFound = await getUser2(id);
            setUser(userFound);
            setPreviousRoute(`/user/${id}`);
          } catch (error) {
            setError(true);
            console.error("Erro ao carregar o usuário:", error);
          }
        } else {
          setUser(currentUser);
        }
      } else {
        setUser(currentUser);
      }
      setLoading(false);
    };
    loadUser();
  }, [currentUser, match]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (contractType === null) return;
        setLoading(true);
        await getEarnings(contractType);
      } catch (error) {
        setError(true);
        reset();
        console.error("Error fetching contracts:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };

    fetchData();
  }, [contractType, getEarnings]);

  const textAboutUserData = currentUser.id === user.id ? "app.earnings.yourData" : "app.earnings.clientData"

  if (loading) {
    return (
      <PageContainer>
        <HeaderPageItem title="app.earnings" showBackButton destination={previousRoute} />
        {user.id && (
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id={textAboutUserData} />
              <Divider />
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage
                id="app.earnings.userName"
                values={{ name: user.name }}
              />
                {` (${user.id})`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage id="app.email"/>: {user.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage
                id="app.earnings.document"
                values={{
                  documentId: user.documentId,
                  documentType: user.documentType,
                }}
              />
            </Typography>
          </CardContent>
        </CardPageItem>
        )}
        <CardPageItem>
          <div style={{ display: "grid", placeItems: "center" }}>
            <CircularProgress style={{ marginTop: "25%" }} />
          </div>
        </CardPageItem>
      </PageContainer>
    );
  }

  if (!user.apt) {
    return (
      <PageContainer>
        <HeaderPageItem title="app.earnings" showBackButton destination={previousRoute} />
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom>
              <FormattedMessage id="app.customer.still.inapt" />
            </Typography>
            {user.id === currentUser.id && (
            <Typography>
              <FormattedHTMLMessage id="app.dashboard.user.pending" />
            </Typography>
            )}
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }

  if (comissionGain !== null) {
    return (
      <PageContainer>
        <HeaderPageItem title="app.earnings" showBackButton onBack={reset} />
        <CardPageItem>
          <Typography variant="h5" color="primary" align="center">
            <FormattedMessage id="app.earnings.choiceButton4" />
          </Typography>
        </CardPageItem>
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id={textAboutUserData} />
              <Divider />
            </Typography>
            <Typography gutterBottom variant="body2">
              <FormattedHTMLMessage
                id="app.earnings.userName"
                values={{ name: user.name }}
              />
              {` (${user.id})`}
            </Typography>
            <Typography gutterBottom variant="body2">
              <FormattedHTMLMessage id="app.email" />: {user.email}
            </Typography>
            <Typography gutterBottom variant="body2">
              <FormattedHTMLMessage
                id="app.earnings.document"
                values={{
                  documentId: user.documentId,
                  documentType: user.documentType,
                }}
              />
            </Typography>
          </CardContent>
        </CardPageItem>
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id="app.earnings.comissionHistory" />
              <Divider />
            </Typography>
            <Typography gutterBottom variant="body2">
              <FormattedHTMLMessage id="app.earnings.comission.description" values={{ year }} />
            </Typography>
            <Typography gutterBottom variant="body2">
              <FormattedMessage id="app.earnings.comission" />
              <Number value={comissionGain} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} />
            </Typography>
          </CardContent>
        </CardPageItem>
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id="app.earnings.comissionHistoryByMonth" />
              <Divider />
            </Typography>
            <Typography variant="body2">
              <FormattedHTMLMessage id="app.earnings.aboutComission" values={{ year }} />
            </Typography>
            <table style={tableStyles.table}>
              <thead style={tableStyles.thead}>
                <tr>
                  <th style={tableStyles.th}>
                    <FormattedMessage id={"app.month.reference"} />
                  </th>
                  <th style={tableStyles.th}>
                    <FormattedMessage id={"app.comission.lct"} />
                  </th>
                </tr>
              </thead>
              <tbody>
                {comissionGainByMonth.map((element, index) => (
                  <tr key={index} style={tableStyles.rowStyle(index)}>
                    <td style={tableStyles.td}><FormattedMessage id={element.key} /></td>
                    <td style={tableStyles.td}><Number value={element.value} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <HeaderPageItem title="app.earnings" showBackButton destination={previousRoute} />
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id="app.earnings.error.title" />
              <Divider />
            </Typography>
            <Typography variant="body2">
              <FormattedHTMLMessage id="app.earnings.error.description" />
            </Typography>
          </CardContent>
        </CardPageItem>
      </PageContainer>
    );
  }

  return (
    <>
      <PageContainer>
        <HeaderPageItem title="app.earnings" showBackButton destination={previousRoute} onBack={contractType ? reset : null} />
        {contractType && (
          <CardPageItem>
            <Typography variant="h5" align="center" color="primary">
              {contractType === "OPEN" 
                ? <FormattedMessage id="app.earnings.choiceButton1" />
                : <FormattedMessage id="app.earnings.choiceButton2" />
              }
            </Typography>
          </CardPageItem>
        )}
        <CardPageItem raised>
          <CardContent>
            <Typography gutterBottom variant="h6" color="primary">
              <FormattedMessage id={textAboutUserData} />
              <Divider />
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage
                id="app.earnings.userName"
                values={{ name: user.name }}
              />
              {` (${user.id})`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage id="app.email"/>: {user.email}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <FormattedHTMLMessage
                id="app.earnings.document"
                values={{
                  documentId: user.documentId,
                  documentType: user.documentType,
                }}
              />
            </Typography>
          </CardContent>
        </CardPageItem>
        <CardPageItem raised>
          {contractType === null ? (
            <CardContent>
              <Typography gutterBottom variant="h6" color="primary">
                <FormattedMessage id="app.earnings.choice" />
                <Divider />
              </Typography>
              <Typography variant="body2" gutterBottom>
                <FormattedMessage id="app.earnings.choice.description" />
              </Typography>
              <List>
                <ListItem button onClick={() => setContractType("OPEN")}>
                  <ListItemText primary={<FormattedMessage id="app.earnings.choiceButton1" />} />
                  <ChevronRight color="action" />
                </ListItem>
                <ListItem button onClick={() => setContractType("CLOSED")}>
                  <ListItemText primary={<FormattedMessage id="app.earnings.choiceButton2" />} />
                  <ChevronRight color="action" />
                </ListItem>
                {Roles.Agent === user.role && (
                  <ListItem button onClick={calculateComission}>
                    <ListItemText primary={<FormattedMessage id="app.earnings.choiceButton4" />} />
                    <ChevronRight color="action" />
                  </ListItem>
                )}
              </List>
            </CardContent>
          ) : (
            <CardContent>
              <Typography gutterBottom variant="h6" color="primary">
                <FormattedHTMLMessage id="app.earnings.description" values={{ year }} />
                <Divider />
              </Typography>

              <Typography gutterBottom variant="body2">
                <FormattedHTMLMessage id="app.earnings.capitalInvested" />
                <Number value={valores.capitalInvested} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} />
              </Typography>
              <Typography gutterBottom variant="body2">
                <FormattedHTMLMessage id="app.earnings.capitalGain" />
                <Number value={valores.capitalGain} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} />
              </Typography>
            </CardContent>
          )}
        </CardPageItem>

        {valores?.contracts.length > 0 && (
          <>
            <CardPageItem raised>
              <CardContent>
                <Typography gutterBottom variant="h6" color="primary">
                  <FormattedMessage id="app.earnings.contractsHistoryByMonth" />
                  <Divider />
                </Typography>
                <Typography variant="body2">
                  <FormattedHTMLMessage id="app.earnings.aboutContractsByMonth" values={{ year }} />
                </Typography>
                <table style={tableStyles.table}>
                  <thead style={tableStyles.thead}>
                    <tr>
                      <th style={tableStyles.td}>
                        <FormattedMessage id={"app.month.reference"} />
                      </th>
                      <th style={tableStyles.td}>
                        <FormattedMessage id={"app.yield"} />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {valores?.earningsByMonth.map((element, index) => (
                      <tr key={index} style={tableStyles.rowStyle(index)}>
                        <td style={tableStyles.td}><FormattedMessage id={element.key} /></td>
                        <td style={tableStyles.td}><Number value={element.value} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </CardPageItem>
            <CardPageItem raised>
              <CardContent>
                <Typography gutterBottom variant="h6" color="primary">
                  <FormattedMessage id="app.earnings.contractsHistory" />
                  <Divider />
                </Typography>
                <Typography variant="body2">
                  <FormattedHTMLMessage id="app.earnings.aboutContracts" values={{ year }} />
                </Typography>
                <table style={{ ...tableStyles.table, display: "block" }}>
                  <thead style={tableStyles.thead}>
                    <tr>
                      <th style={tableStyles.th}><FormattedMessage id={"app.id"} /></th>
                      <th style={tableStyles.th}><FormattedMessage id={"app.createdAt"} /></th>
                      <th style={tableStyles.th}><FormattedMessage id={"app.dueDate"} /></th>
                      <th style={tableStyles.th}><FormattedMessage id={"app.value"} /></th>
                      <th style={tableStyles.th}><FormattedMessage id={"app.yield"} /></th>
                    </tr>
                  </thead>
                  <tbody>
                    {valores?.contracts.map((element, index) => (
                      <tr key={index} style={tableStyles.rowStyle(index)}>
                        <td style={{...tableStyles.td, minWidth: "none"}}>C {element.id}</td>
                        <td style={tableStyles.td}><Moment date={element.createdAt} format={currentUser.locale === "en" ? "MM/DD/YY" : "DD/MM/YYYY" } /></td>
                        <td style={tableStyles.td}><Moment date={element.dueDate} format={currentUser.locale === "en" ? "MM/DD/YY" : "DD/MM/YYYY" } /></td>
                        <td style={tableStyles.td}><Number value={element.amount} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} /></td>
                        <td style={tableStyles.td}><Number value={element.earning} currency="LCT" taxResidence={user.taxResidence} hideValue={currentUser.hideValues} /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardContent>
            </CardPageItem>
          </>
        )}
      </PageContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { currentUser: state.user.user.me };
};

EarningsPage.propTypes = {
  theme: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default compose(connect(mapStateToProps), withTheme)(EarningsPage);
