import MomentUtils from "@date-io/moment";
import { Button, CircularProgress, CssBaseline, Snackbar, Typography } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import moment from "moment";
import "moment/locale/pt-br";
import queryString from "query-string";
import React, { Component } from "react";
import cookie from "react-cookies";
import { Detector } from "react-detect-offline";
import { FormattedMessage, IntlProvider, addLocaleData } from "react-intl";
import brLocaleData from "react-intl/locale-data/br";
import enLocaleData from "react-intl/locale-data/en";
import esLocaleData from "react-intl/locale-data/es";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import Alert from "./Alert";
import * as actions from "./actions";
import BottomNavigationBar from "./custom/BottomNavigationBar";
import MySnackbarContent from "./custom/MySnackbarContent";
import {
  ConfirmEmailForm,
  Contract,
  ContractAgentDetail,
  CustomerContracts,
  CustomerDashboard,
  CustomerData,
  CustomerFormData,
  CustomerMore,
  CustomerNewContract,
  Dashboard,
  Signin,
  Signout,
  UserStory,
} from "./pages";
import AdminContract from "./pages/AdminContract";
import AdminDashboard from "./pages/AdminDashboard";
import AdminDeposits from "./pages/AdminDeposits";
import AdminFinancial from "./pages/AdminFinancial";
import AdminKyc from "./pages/AdminKyc";
import AdminStatementDataUser from "./pages/AdminStatementDataUser";
import AdminUserData from "./pages/AdminUserData";
import AdminUserFormData from "./pages/AdminUserFormData";
import AdminUsers from "./pages/AdminUsers";
import AdminWithdrawal from "./pages/AdminWithdrawal";
import AgentContract from "./pages/AgentContract";
import AgentCustomerList from "./pages/AgentCustomerList";
import AgentDataCustomer from "./pages/AgentDataCustomer";
import AgentEditContract from "./pages/AgentEditContract";
import AgentFormContract from "./pages/AgentFormContract";
import AgentFormCustomer from "./pages/AgentFormCustomer";
import AgentNewClient from "./pages/AgentNewClient";
import AuditorDashboard from "./pages/AuditorDashboard";
import AuthorizeWithdrawal from "./pages/AuthorizeWithdrawal";
import BusinessDashboard from "./pages/BusinessDashboard";
import BusinessUserData from "./pages/BusinessUserData";
import ChangeLanguageForm from "./pages/ChangeLanguageForm";
import ChangePasswordForm from "./pages/ChangePasswordForm";
import ConfigContract from "./pages/ConfigContract";
import ConfigEmailTemplate from "./pages/ConfigEmailTemplate";
import ConfigForm from "./pages/ConfigForm";
import ConfigSegment from "./pages/ConfigSegment";
import ConfirmEmail from "./pages/ConfirmEmail";
import ContractReport from "./pages/ContractReport";
import ContractReportJustOpened from "./pages/ContractReportJustOpened";
import ContractReportRedeemed from "./pages/ContractReportRedeemed";
import ContractReportTerminated from "./pages/ContractReportTerminated";
import ContractReportWaitingValidation from "./pages/ContractReportWaitingValidation";
import CustomerActiveContract from "./pages/CustomerActiveContract";
import CustomerContract from "./pages/CustomerContract";
import CustomerContractChart from "./pages/CustomerContractChart";
import CustomerContractDetail from "./pages/CustomerContractDetail";
import CustomerContractHistory from "./pages/CustomerContractHistory";
import CustomerDocs from "./pages/CustomerDocs";
import CustomerFormWithdrawFinal from "./pages/CustomerFormWithdrawFinal";
import CustomerHelp from "./pages/CustomerHelp";
import CustomerReaportContract from "./pages/CustomerReaportContract";
import DetailHistoryWithdrawal from "./pages/DetailHistoryWithdrawal";
import EarningsPage from "./pages/EarningsPage";
import FinanceDashboard from "./pages/FinanceDashboard";
import FormWithdraw from "./pages/FormWithdraw";
import HistoryWithdrawal from "./pages/HistoryWithdrawal";
import KycDashboard from "./pages/KycDashboard";
import MenuReports from "./pages/MenuReports";
import Register from "./pages/Register";
import ReportHierarchical from "./pages/ReportHierarchical";
import ReportManagerAndAgents from "./pages/ReportManagersAndAgents";
import ReportUserContracts from "./pages/ReportUserContracts";
import UserReport from "./pages/UserReport";
import { createCustomTheme, customPalettes } from "./theme/createCustomTheme";
import messages_br from "./translations/br.json";
import messages_en from "./translations/en.json";
import messages_es from "./translations/es.json";
import { Roles, getActualRole } from "./util";
import Beneficiary from "./pages/Beneficiary";
import ReportContractsCommissions from "./pages/ReportContractsCommissions";
import ReportAgentPortfolio from "./pages/ReportAgentPortfolio";
import ReportAgentsFinancial from "./pages/ReportAgentsFinancial";

moment.tz.setDefault("America/Sao_Paulo");
moment.locale("pt-br");
Moment.globalFormat = "DD/MM/YYYY HH:mm";

const GenericMessage = ({ title, msg, btn, history, btnVariant }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 64,
        padding: 32,
      }}
    >
      <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
        {title}
      </Typography>
      <Typography gutterBottom style={{ textAlign: "center" }}>
        {msg}
      </Typography>
      <Button color="primary" variant={btnVariant} href={history ? `/login?continue=${history.location.pathname}` : "/"} style={{ marginTop: 16 }}>
        {btn}
      </Button>
    </div>
  );
};

const Message401 = () => (
  <GenericMessage title={<FormattedMessage id="app.error.401" />} msg={<FormattedMessage id="app.error.401.msg" />} btn={<FormattedMessage id="app.error.401" />} btnVariant="outlined" />
);
const Message404 = ({ title, msg, btn, history, btnVariant }) => <GenericMessage title={title} msg={msg} btn={btn} history={history} btnVariant={btnVariant} />;
const Message404Disconnected = (props) => (
  <Message404
    title={<FormattedMessage id="app.error.necessaryToEnter" />}
    msg={<FormattedMessage id="app.error.diconnected" />}
    btn={<FormattedMessage id="app.signin.button" />}
    btnVariant="contained"
    {...props}
  />
);
const Message404Private = () => (
  <Message404 title={<FormattedMessage id="app.error.404" />} msg={<FormattedMessage id="app.error.pageNotFound" />} btn={<FormattedMessage id="app.backToStart" />} btnVariant="outlined" />
);

const isInWebAppiOS = () => ("standalone" in window.navigator && window.navigator.standalone !== undefined ? window.navigator.standalone : false);
const isInWebAppChrome = () => window.matchMedia("(display-mode: standalone)").matches;

addLocaleData(enLocaleData);
addLocaleData(brLocaleData);
addLocaleData(esLocaleData);

const messages = {
  br: messages_br,
  en: messages_en,
  es: messages_es,
};

class App extends Component {
  state = {
    loading: true,
    openConectionBanner: false,
  };
  navBarIndex = 0;

  componentDidMount() {
    if (this.props.history.location.pathname == "/logout") {
      this.setState({ loading: false });
    } else if (cookie.load("LCAuthToken")) {
      const { currentUser } = this.props;
      if (!currentUser.id) {
        this.props.getMe().then((r) => {
          const query = queryString.parse(this.props.location.search);
          this.props.history.push(query.continue ? query.continue : this.props.history.location);
          this.setState({ loading: false });
        });
      }
    } else {
      this.setState({ loading: false });
    }
  }

  renderConectionStatusChanged = (online) => (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={this.state.openConectionBanner}
    >
      <MySnackbarContent
        variant={online ? "success" : "error"}
        message={online ? "app.weAreBack" : "app.lost.connection"}
        showClose={false}
        showRefreshButton={online}
        onRefresh={(e) => this.props.history.go(0)}
      />
    </Snackbar>
  );

  onChangeConnectionStatus = (online) => {
    this.setState({ openConectionBanner: true });
  };

  ERoute = ({ component, components, redirect, path, navBarIndex, ...rest }) => {
    this.navBarIndex = navBarIndex;
    const { currentUser } = this.props;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    let Component = component;
    if (components) {
      const currentUserRole = getActualRole(currentUser);
      Component = components[currentUserRole];
      if (!Component) {
        return <Message401 />;
      }
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  };

  PublicRoutes = (props) => (
    <Switch>
      <this.ERoute exact path="/" redirect="/login" />
      <this.ERoute exact path="/logout" component={Signout} />
      <this.ERoute exact path="/login" component={Signin} />
      <this.ERoute exact path="/register" component={Register} />
      <this.ERoute exact path="/onboarding" component={Register} />
      <this.ERoute path="/confirmEmail/:token" component={ConfirmEmail} />
      <this.ERoute path="/authorize/:token" component={AuthorizeWithdrawal} />
      <Route path="*" component={Message404Disconnected} {...props} />
    </Switch>
  );

  PrivateRoutes = () => (
    <>
      <Switch>
        <this.ERoute exact navBarIndex={0} path="/" redirect="/dashboard" />
        <this.ERoute exact navBarIndex={0} path="/login" redirect="/dashboard" />
        <this.ERoute exact navBarIndex={0} path="/logout" component={Signout} />
        <this.ERoute
          exact
          navBarIndex={0}
          path="/dashboard"
          components={{
            [Roles.Customer]: CustomerDashboard,
            [Roles.Agent]: Dashboard,
            [Roles.AgentsManager]: Dashboard,
            [Roles.Admin]: AdminDashboard,
            [Roles.Kyc]: KycDashboard,
            [Roles.BusinessManager]: BusinessDashboard,
            [Roles.FinanceManager]: FinanceDashboard,
            [Roles.Auditor]: AuditorDashboard,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={0}
          path="/authorize/:token"
          components={{
            [Roles.Admin]: AuthorizeWithdrawal,
            [Roles.Customer]: AuthorizeWithdrawal,
            [Roles.Kyc]: AuthorizeWithdrawal,
            [Roles.Agent]: AuthorizeWithdrawal,
            [Roles.AgentsManager]: AuthorizeWithdrawal,
            [Roles.BusinessManager]: AuthorizeWithdrawal,
            [Roles.FinanceManager]: AuthorizeWithdrawal,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contracts"
          components={{
            [Roles.Customer]: CustomerContracts,
            [Roles.Agent]: CustomerContracts,
            [Roles.AgentsManager]: CustomerContracts,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractAgent/:id"
          components={{
            [Roles.Agent]: ContractAgentDetail,
            [Roles.AgentsManager]: ContractAgentDetail,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/userContract/:id"
          components={{
            [Roles.Agent]: AgentContract,
            [Roles.AgentsManager]: AgentContract,
            [Roles.Admin]: AdminContract,
            [Roles.FinanceManager]: AdminContract,
            [Roles.BusinessManager]: AdminContract,
          }}
        />
        <this.ERoute exact navBarIndex={0} path="/config" component={ConfigForm} />
        <this.ERoute exact navBarIndex={0} path="/configContract" component={ConfigContract} />
        <this.ERoute exact navBarIndex={0} path="/ConfigSegment" component={ConfigSegment} />
        <this.ERoute
          exact
          navBarIndex={0}
          path="/confirmEmailForm"
          components={{
            [Roles.Customer]: ConfirmEmailForm,
            [Roles.Agent]: ConfirmEmailForm,
            [Roles.AgentsManager]: ConfirmEmailForm,
            [Roles.Admin]: ConfirmEmailForm,
            [Roles.Kyc]: ConfirmEmailForm,
            [Roles.FinanceManager]: ConfirmEmailForm,
            [Roles.Auditor]: ConfirmEmailForm,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/activityContract/:id"
          components={{
            [Roles.Customer]: CustomerActiveContract,
            [Roles.Agent]: CustomerActiveContract,
            [Roles.AgentsManager]: CustomerActiveContract,
            [Roles.Admin]: CustomerActiveContract,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contract/:id"
          components={{
            [Roles.Customer]: CustomerContract,
            [Roles.Agent]: CustomerContract,
            [Roles.AgentsManager]: CustomerContract,
            [Roles.Admin]: CustomerContract,
            [Roles.FinanceManager]: CustomerContract,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractDetail/:id"
          components={{
            [Roles.Customer]: CustomerContractDetail,
            [Roles.Agent]: CustomerContractDetail,
            [Roles.AgentsManager]: CustomerContractDetail,
            [Roles.Admin]: CustomerActiveContract,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractHistory/:id"
          components={{
            [Roles.Customer]: CustomerContractHistory,
            [Roles.Agent]: CustomerContractHistory,
            [Roles.AgentsManager]: CustomerContractHistory,
            [Roles.Admin]: CustomerContractHistory,
            [Roles.FinanceManager]: CustomerContractHistory,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractChart/:id/:month/:year"
          components={{
            [Roles.Customer]: CustomerContractChart,
            [Roles.Agent]: CustomerContractChart,
            [Roles.AgentsManager]: CustomerContractChart,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/editContract/:id"
          components={{
            [Roles.Agent]: AgentEditContract,
            [Roles.AgentsManager]: AgentEditContract,
            [Roles.Admin]: AgentEditContract,
            [Roles.FinanceManager]: AgentEditContract,
          }}
        />

        <this.ERoute exact navBarIndex={2} path="/newContact" components={{ [Roles.Customer]: CustomerNewContract }} />
        <this.ERoute exact navBarIndex={2} path="/reaport/:id" components={{ [Roles.Customer]: CustomerReaportContract }} />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/newContract/:id"
          components={{
            [Roles.Agent]: AgentFormContract,
            [Roles.AgentsManager]: AgentFormContract,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractReport"
          components={{
            [Roles.Admin]: ContractReport,
            [Roles.AgentsManager]: ContractReport,
            [Roles.FinanceManager]: ContractReport,
            [Roles.BusinessManager]: ContractReport,
            [Roles.Auditor]: ContractReport,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/earnings"
          components={{
            [Roles.AgentsManager]: EarningsPage,
            [Roles.Agent]: EarningsPage,
            [Roles.Customer]: EarningsPage
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/user/earnings/:id"
          components={{
            [Roles.Admin]: EarningsPage,
            [Roles.FinanceManager]: EarningsPage,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractReportWaitingValidation"
          components={{
            [Roles.Admin]: ContractReportWaitingValidation,
            [Roles.FinanceManager]: ContractReportWaitingValidation,
            [Roles.BusinessManager]: ContractReportWaitingValidation,
            [Roles.Auditor]: ContractReportWaitingValidation,
            [Roles.AgentsManager]: ContractReportWaitingValidation,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractReportTerminated"
          components={{
            [Roles.Admin]: ContractReportTerminated,
            [Roles.AgentsManager]: ContractReportTerminated,
            [Roles.FinanceManager]: ContractReportTerminated,
            [Roles.BusinessManager]: ContractReportTerminated,
            [Roles.Auditor]: ContractReportTerminated,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractReportRedeemed"
          components={{
            [Roles.Admin]: ContractReportRedeemed,
            [Roles.AgentsManager]: ContractReportRedeemed,
            [Roles.FinanceManager]: ContractReportRedeemed,
            [Roles.BusinessManager]: ContractReportRedeemed,
            [Roles.Auditor]: ContractReportRedeemed,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractReportJustOpened"
          components={{
            [Roles.Admin]: ContractReportJustOpened,
            [Roles.AgentsManager]: ContractReportJustOpened,
            [Roles.FinanceManager]: ContractReportJustOpened,
            [Roles.BusinessManager]: ContractReportJustOpened,
            [Roles.Auditor]: ContractReportJustOpened,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/reportHierarchical"
          components={{
            [Roles.Admin]: ReportHierarchical,
            [Roles.FinanceManager]: ReportHierarchical,
            [Roles.BusinessManager]: ReportHierarchical,
            [Roles.AgentsManager]: ReportHierarchical,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/managerAndAgents"
          components={{
            [Roles.Admin]: ReportManagerAndAgents,
            [Roles.FinanceManager]: ReportManagerAndAgents,
            [Roles.BusinessManager]: ReportManagerAndAgents,
            [Roles.AgentsManager]: ReportManagerAndAgents,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/contractsCommissions"
          components={{
            [Roles.Admin]: ReportContractsCommissions,
            [Roles.FinanceManager]: ReportContractsCommissions,
            [Roles.BusinessManager]: ReportContractsCommissions,
            [Roles.AgentsManager]: ReportContractsCommissions,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/agentPortfolio"
          components={{
            [Roles.Admin]: ReportAgentPortfolio,
            [Roles.FinanceManager]: ReportAgentPortfolio,
            [Roles.BusinessManager]: ReportAgentPortfolio,
            [Roles.AgentsManager]: ReportAgentPortfolio,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/agentsFinancial"
          components={{
            [Roles.Admin]: ReportAgentsFinancial,
            [Roles.FinanceManager]: ReportAgentsFinancial,
            [Roles.BusinessManager]: ReportAgentsFinancial,
            [Roles.AgentsManager]: ReportAgentsFinancial,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/reportUserContracts"
          components={{
            [Roles.Admin]: ReportUserContracts,
            [Roles.FinanceManager]: ReportUserContracts,
            [Roles.BusinessManager]: ReportUserContracts,
            [Roles.Agent]: ReportUserContracts,
            [Roles.AgentsManager]: ReportUserContracts,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/customerDocs"
          components={{
            [Roles.Customer]: CustomerDocs,
            [Roles.Agent]: CustomerDocs,
            [Roles.AgentsManager]: CustomerDocs,
            [Roles.BusinessManager]: CustomerDocs,
            [Roles.FinanceManager]: CustomerDocs,
            [Roles.Kyc]: CustomerDocs,
            [Roles.Auditor]: CustomerDocs,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/customers"
          components={{
            [Roles.Agent]: AgentCustomerList,
            [Roles.AgentsManager]: AgentCustomerList,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/customer/:id"
          components={{
            [Roles.Agent]: AgentDataCustomer,
            [Roles.AgentsManager]: AgentDataCustomer,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/customerData"
          components={{
            [Roles.Customer]: CustomerData,
            [Roles.Agent]: CustomerData,
            [Roles.AgentsManager]: CustomerData,
            [Roles.Admin]: CustomerData,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/customerFormData"
          components={{
            [Roles.Customer]: CustomerFormData,
            [Roles.Agent]: CustomerFormData,
            [Roles.AgentsManager]: CustomerFormData,
            [Roles.Admin]: CustomerFormData,
            [Roles.Kyc]: CustomerFormData,
            [Roles.FinanceManager]: CustomerFormData,
            [Roles.BusinessManager]: CustomerFormData,
            [Roles.Auditor]: CustomerFormData,
          }}
        />
        <this.ERoute exact navBarIndex={3} path="/deposits" components={{ [Roles.Admin]: AdminDeposits }} />
        <this.ERoute exact navBarIndex={3} path="/financial" components={{ [Roles.Admin]: AdminFinancial }} />
        <this.ERoute exact navBarIndex={2} path="/kyc/:id" components={{ [Roles.Admin]: AdminKyc, [Roles.Kyc]: AdminKyc, [Roles.FinanceManager]: AdminKyc }} />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/withdraw/:id"
          components={{
            [Roles.Admin]: AdminWithdrawal,
            [Roles.FinanceManager]: AdminWithdrawal,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/withdraw"
          components={{
            [Roles.Customer]: FormWithdraw,
            [Roles.Agent]: FormWithdraw,
            [Roles.AgentsManager]: FormWithdraw,
            [Roles.Admin]: FormWithdraw,
          }}
        />
        <this.ERoute exact navBarIndex={1} path="/withdrawFinal/:id" components={{ [Roles.Customer]: CustomerFormWithdrawFinal }} />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/historyWithdrawal"
          components={{
            [Roles.Admin]: HistoryWithdrawal,
            [Roles.FinanceManager]: HistoryWithdrawal,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={3}
          path="/detailHistoryWithdrawal/:id"
          components={{
            [Roles.Admin]: DetailHistoryWithdrawal,
            [Roles.FinanceManager]: DetailHistoryWithdrawal,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/newUser"
          components={{
            [Roles.Agent]: AgentFormCustomer,
            [Roles.AgentsManager]: AgentFormCustomer,
            [Roles.Admin]: AdminUserFormData,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/users"
          components={{
            [Roles.Admin]: AdminUsers,
            [Roles.Kyc]: AdminUsers,
            [Roles.BusinessManager]: AdminUsers,
            [Roles.FinanceManager]: AdminUsers,
            [Roles.AgentsManager]: AgentCustomerList,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/user/:id"
          components={{
            [Roles.Admin]: AdminUserData,
            [Roles.Kyc]: AdminUserData,
            [Roles.BusinessManager]: BusinessUserData,
            [Roles.AgentsManager]: BusinessUserData,
            [Roles.FinanceManager]: AdminUserData,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/users/:id"
          components={{
            [Roles.Admin]: AdminUserFormData,
            [Roles.Kyc]: AdminUserFormData,
            [Roles.FinanceManager]: AdminUserFormData,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/userReport"
          components={{
            [Roles.Admin]: UserReport,
            [Roles.BusinessManager]: UserReport,
            [Roles.FinanceManager]: UserReport,
          }}
        />

        <this.ERoute
          exact
          navBarIndex={3}
          path="/history"
          components={{
            [Roles.Customer]: UserStory,
            [Roles.Agent]: UserStory,
            [Roles.AgentsManager]: UserStory,
            [Roles.Admin]: UserStory,
          }}
        />
        <this.ERoute exact navBarIndex={2} path="/accountUser/:id" components={{ [Roles.Admin]: AdminStatementDataUser }} />
        <this.ERoute
          exact
          navBarIndex={2}
          path="/newClient"
          components={{
            [Roles.Agent]: AgentNewClient,
            [Roles.AgentsManager]: AgentNewClient,
          }}
        />
        <this.ERoute exact navBarIndex={0} path="/more" component={CustomerMore} />
        <this.ERoute exact navBarIndex={0} path="/beneficiary" component={Beneficiary} />
        <this.ERoute
          exact
          navBarIndex={0}
          path="/confirmEmail/:token"
          components={{
            [Roles.Customer]: ConfirmEmail,
            [Roles.Agent]: ConfirmEmail,
            [Roles.AgentsManager]: ConfirmEmail,
            [Roles.Admin]: ConfirmEmail,
            [Roles.Kyc]: ConfirmEmail,
            [Roles.BusinessManager]: ConfirmEmail,
            [Roles.FinanceManager]: ConfirmEmail,
            [Roles.Auditor]: ConfirmEmail,
          }}
        />
        <this.ERoute exact navBarIndex={0} path="/changePassword" component={ChangePasswordForm} />
        <this.ERoute
          exact
          navBarIndex={0}
          path="/confirmEmailForm"
          components={{
            [Roles.Customer]: ConfirmEmailForm,
            [Roles.Agent]: ConfirmEmailForm,
            [Roles.AgentsManager]: ConfirmEmailForm,
            [Roles.Admin]: ConfirmEmailForm,
            [Roles.Kyc]: ConfirmEmailForm,
            [Roles.BusinessManager]: ConfirmEmailForm,
            [Roles.FinanceManager]: ConfirmEmailForm,
          }}
        />

        <this.ERoute exact navBarIndex={0} path="/language" component={ChangeLanguageForm} />
        <this.ERoute exact navBarIndex={0} path="/template" component={ConfigEmailTemplate} />
        <this.ERoute exact navBarIndex={3} path="/help" component={CustomerHelp} />

        <this.ERoute
          exact
          navBarIndex={1}
          path="/reports"
          components={{
            [Roles.Admin]: MenuReports,
            [Roles.FinanceManager]: MenuReports,
            [Roles.BusinessManager]: MenuReports,
            [Roles.Auditor]: MenuReports,
            [Roles.AgentsManager]: MenuReports,
          }}
        />
        <this.ERoute
          exact
          navBarIndex={1}
          path="/user/:id/history"
          components={{
            [Roles.Admin]: UserStory,
            [Roles.FinanceManager]: UserStory,
          }}
        />
        <Route path="*" component={Message404Private} />
      </Switch>
      <BottomNavigationBar index={this.navBarIndex} />
    </>
  );

  render() {
    const { currentUser } = this.props;
    let locale = cookie.load("lclocale") ? cookie.load("lclocale") : "en";
    moment.locale(locale == "br" ? "pt-br" : locale);
    Moment.globalFormat = locale == "br" ? "DD/MM/YYYY" : "YYYY-MM-DD";
    return (
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        <MuiThemeProvider theme={createCustomTheme(customPalettes.dark)}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Alert />
              {(isInWebAppiOS() || isInWebAppChrome()) && <Detector render={({ online }) => this.renderConectionStatusChanged(online)} onChange={this.onChangeConnectionStatus} />}
              {this.state.loading ? <CircularProgress style={{ marginTop: "75%" }} /> : currentUser.id ? <this.PrivateRoutes /> : <this.PublicRoutes {...this.props} />}
            </div>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.user.user.me,
  };
}

export default withRouter(connect(mapStateToProps, actions)(App));
